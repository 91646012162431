// material-ui
import { Box, Grid } from '@mui/material';
import Logo from 'components/logo';
import { PropsWithChildren } from 'react';
import { AuthBackground } from './AuthBackground';
// project import
import AuthCard from './AuthCard';

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapper = ({ children }: PropsWithChildren) => (
  <Box sx={{ minHeight: '100vh' }}>
    <AuthBackground />
    <Grid container direction="column" justifyContent="flex-end">
      <Grid item xs={12} sx={{ ml: 3, mt: 3 }}>
        <Logo />
      </Grid>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: {
              xs: 'calc(100vh - 210px)',
              sm: 'calc(100vh - 134px)',
              md: 'calc(100vh - 112px)'
            }
          }}
        >
          <Grid item>
            <AuthCard>{children}</AuthCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default AuthWrapper;
