'use client';

// next
// material-ui
import { FormControl, TextField, Grid, Stack, Typography, MenuItem, Link } from '@mui/material';
import { getCsrfToken } from 'next-auth/react';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
// project import
import AuthWrapper from 'sections/auth/AuthWrapper';
import AuthLogin from 'sections/auth/auth-forms/AuthLogin';
import useConfig from '~/hooks/useConfig';
import { LanguageCode, useGetSupportedLanguages } from '~/hooks/useSupportedLanguages';

export const handlePolicyHREF = (currLanguage: string) => {
  if (currLanguage === 'he') {
    return 'https://fit-x.app/home/Privacy';
  } else if (currLanguage === 'ar') {
    return 'https://fit-x.app/home/Privacyar';
  } else {
    return 'https://fit-x.app/home/Privacyen';
  }
};

export const handleTOUBHREF = (currLanguage: string) => {
  if (currLanguage === 'he') {
    return 'https://fit-x.app/home/Privacy';
  } else if (currLanguage === 'ar') {
    return 'https://fit-x.app/home/Privacyar';
  } else {
    return 'https://fit-x.app/home/Privacyen';
  }
};

export const handleTOUHREF = (currLanguage: string) => {
  if (currLanguage === 'he') {
    return 'https://fit-x.app/home/TermsOfService';
  } else if (currLanguage === 'ar') {
    return 'https://fit-x.app/home/TermsOfServicear';
  } else {
    return 'https://fit-x.app/home/TermsOfServiceen';
  }
};

export default function SignIn() {
  const [csrfToken, setCsrfToken] = useState<string | undefined>();
  const { i18n, onChangeLocalization } = useConfig();
  const [currLanguage, setCurrLanguage] = useState(i18n);
  const supportedLanguages = useGetSupportedLanguages();

  const handleLanguageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrLanguage(e.target.value as LanguageCode);
    onChangeLocalization?.(e.target.value);
  };

  useEffect(() => {
    const getToken = async () => {
      const csrfTokenData = await getCsrfToken();
      setCsrfToken(csrfTokenData);
    };

    getToken();
  }, []);

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography component="div" variant="h3">
              <FormattedMessage id="welcomming" />
            </Typography>

            <FormControl sx={{ minWidth: '120px' }} size="small">
              <TextField
                label={<FormattedMessage id="language" />}
                select
                InputLabelProps={{ shrink: true }}
                value={currLanguage}
                id="lang"
                onChange={handleLanguageChanged}
              >
                {supportedLanguages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    {lang.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthLogin csrfToken={csrfToken} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <NextLink prefetch={false} href={handlePolicyHREF(currLanguage)} passHref legacyBehavior>
              <Link variant="body1" color="primary">
                <FormattedMessage id="privacy-policy" />
              </Link>
            </NextLink>
            <NextLink prefetch={false} href={handleTOUBHREF(currLanguage)} passHref legacyBehavior>
              <Link variant="body1" color="primary">
                <FormattedMessage id="TOU-business" />
              </Link>
            </NextLink>
            <NextLink prefetch={false} href={handleTOUHREF(currLanguage)} passHref legacyBehavior>
              <Link variant="body1" color="primary">
                <FormattedMessage id="TOU" />
              </Link>
            </NextLink>
          </Stack>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}
