'use client';

// assets
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
// material-ui
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material';
// project import
import IconButton from 'components/@extended/IconButton';
import { Formik } from 'formik';
// next
import { signIn } from 'next-auth/react';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// third party
import * as Yup from 'yup';
import LoadingButton from '~/components/@extended/LoadingButton';
import { getCurrentLocaleReqHeader } from '~/utils/i18nGlobal';

// ============================|| AWS CONNITO - LOGIN ||============================ //

type AuthLoginProps = {
  csrfToken?: string;
  providers?: object;
};

const AuthLogin = ({ csrfToken }: AuthLoginProps) => {
  const [checked, setChecked] = useState(false);
  const [capsWarning, setCapsWarning] = useState(false);
  const intl = useIntl();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLElement>) => {
    if (keyEvent.getModifierState('CapsLock')) {
      setCapsWarning(true);
    } else {
      setCapsWarning(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          GymCode: '',
          UserName: '',
          Password: '',
          RememberMe: true,
          submit: null
        }}
        validationSchema={Yup.object().shape({
          GymCode: Yup.string().required(intl.formatMessage({ id: 'required' })),
          UserName: Yup.string().required(intl.formatMessage({ id: 'required' })),
          Password: Yup.string().required(intl.formatMessage({ id: 'required' })),
          RememberMe: Yup.boolean().default(true)
        })}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          const currentLanguage = getCurrentLocaleReqHeader();

          signIn('login', {
            redirect: false, // Changing this might break things.
            GymCode: values.GymCode,
            UserName: values.UserName,
            currentLanguage,
            Password: values.Password,
            RememberMe: checked
          }).then(
            (res) => {
              setSubmitting(false);
              if (res?.error) {
                setErrors({ submit: res.error });
              } else {
                window.location.href = '/';
              }
            },
            (res) => {
              setErrors({ submit: res.error });
              setSubmitting(false);
            }
          );
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="gymcode-login">
                    <FormattedMessage id="gymcode" />
                  </InputLabel>
                  <OutlinedInput
                    id="gymcode-login"
                    type="string"
                    value={values.GymCode}
                    name="GymCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'enter-gymcode' })}
                    fullWidth
                    error={Boolean(touched.GymCode && errors.GymCode)}
                  />
                </Stack>
                {touched.GymCode && errors.GymCode && (
                  <FormHelperText error id="standard-weight-helper-text-gymcode-login">
                    {errors.GymCode}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="username-login">
                    <FormattedMessage id="username" />
                  </InputLabel>
                  <OutlinedInput
                    id="username-login"
                    type="string"
                    value={values.UserName}
                    name="UserName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'enter-username' })}
                    fullWidth
                    error={Boolean(touched.UserName && errors.UserName)}
                  />
                </Stack>
                {touched.UserName && errors.UserName && (
                  <FormHelperText error id="standard-weight-helper-text-username-login">
                    {errors.UserName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-login">
                    {' '}
                    <FormattedMessage id="psswd" />
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    color={capsWarning ? 'warning' : 'primary'}
                    error={Boolean(touched.Password && errors.Password)}
                    id="-password-login"
                    type={showPassword ? 'text' : 'password'}
                    value={values.Password}
                    name="Password"
                    onBlur={(event) => {
                      setCapsWarning(false);
                      handleBlur(event);
                    }}
                    onKeyDown={onKeyDown}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="secondary"
                        >
                          {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder={intl.formatMessage({ id: 'enter-psswd' })}
                  />
                  {capsWarning && (
                    <Typography variant="caption" sx={{ color: 'warning.main' }} id="warning-helper-text-password-login">
                      Caps lock on!
                    </Typography>
                  )}
                </Stack>
                {touched.Password && errors.Password && (
                  <FormHelperText error id="standard-weight-helper-text-password-login">
                    {errors.Password}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sx={{ mt: -1 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(event) => setChecked(event.target.checked)}
                        name="checked"
                        color="primary"
                        size="small"
                      />
                    }
                    label={
                      <Typography variant="h6">
                        <FormattedMessage id="keep-signin" />
                      </Typography>
                    }
                  />
                </Stack>
              </Grid>
              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>
                    <FormattedMessage id="error-login" />
                  </FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                  <FormattedMessage id="login" /> {isSubmitting && <LoadingButton loading />}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthLogin;
