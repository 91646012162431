'use client';

// material-ui
import { ButtonBase } from '@mui/material';
import NextLink from 'next/link';
import * as PropTypes from 'prop-types';
import { useDefaultPath } from '~/hooks/useDefaultPath';
import LogoIcon from './LogoIcon';
// project import
import LogoMain from './LogoMain';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse, isIcon, sx, to }) => {
  const APP_DEFAULT_PATH = useDefaultPath();
  return (
    <NextLink prefetch={false} href={!to ? APP_DEFAULT_PATH : to} passHref legacyBehavior>
      <ButtonBase disableRipple sx={sx}>
        {isIcon ? <LogoIcon /> : <LogoMain reverse={reverse} />}
      </ButtonBase>
    </NextLink>
  );
};

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
