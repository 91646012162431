'use client';

// material-ui
import { Box, useTheme } from '@mui/material';
// project import
import Image from 'next/image';
import React from 'react';

// ==============================|| AUTH BLUR BACK SVG ||============================== //
const logo = '/assets/images/login-bg.png';

export const AuthBackground = () => {
  const theme = useTheme();
  return (
    <Box
      style={{
        position: 'absolute',
        filter: 'blur(18px)',
        zIndex: -1,
        top: 0,
        direction: theme.direction,
        left: 0,
        transform: 'translate(0,32%)'
      }}
    >
      <Image src={logo} alt="Fitx" fill={false} width={0} height={0} style={{ width: '100%', height: 'calc(100vh - 365px)' }} />
    </Box>
  );
};
